    <template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getDealSteps">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addDealStep()" color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('deal.self') }}{{ process ? (': «' + process.name + '»') : '' }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4"  class="text-right">
                                        <v-btn :to="{name: 'deal_step.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                        <v-btn v-if="deal_stepItems.length === 0"  @click="dialogCopy=true" class="infinity_button ml-4" color="primary">
                                            {{$t('copy') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <TabsNav :items="items"/>
                                    </v-col>
                                </v-row>


                                <v-row v-if="filter_show">

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="deal_step" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="business_process"
                                                      :items="businessProcessItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('business_process')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table
                            item-key="id" ref="table" :headers="headers" :items="deal_stepItems"
                            :options.sync="options" disable-pagination
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                            :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                            hide-default-footer
                            class="sortable-table elevation-0"
                        >

                            <template v-slot:item.sort>
                                <v-btn icon class="handle">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <v-icon v-if="!item.deleted" @click="deleteDealStep(item)" small>mdi-delete-outline</v-icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs"
                                                  v-on="on"
                                                  class="cursor-pointer font_weight_600" style="font-size: 16px" @click="editDealStep(item)" >
                                                    {{  item.name }}
                                                </span>
                                        </template>
                                        <span>
                                            <div v-if="item.responsibles.length > 0">
                                                {{$t('who_can_press_button')}}
                                                <div v-for="(admin) in item.responsibles" :key="admin.id">
                                                   - {{admin.name}}
                                                </div>
                                            </div>
                                            <div v-if="item.departments.length > 0">
                                                {{$t('which_department_can_click')}}
                                                <div v-for="(department, index) in item.departments" :key="department">
                                                   - {{ (departments && departments[department] && departments[department].name) ? departments[department].name : $t('no')}}
                                                </div>
                                            </div>


                                        </span>
                                    </v-tooltip>

                                    <v-icon small v-if="item.button"> mdi-arrow-right-thin</v-icon>

                                    {{item.button}}

                                    <template>
                                        <ValidationObserver ref="observer3" v-slot="{ invalid, validated, passes, validate }">
                                        <v-menu
                                            v-model="menu[item.id]"
                                            bottom
                                            left
                                            :close-on-click="false"
                                            :close-on-content-click="false"
                                            ref="menu"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                icon
                                                @click="openMenu(item)"
                                            >
                                                <v-icon>mdi-plus-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card  flat  max-height="500">
                                            <v-card-title class="pb-0 mb-0" v-html="$t('from_status_you_can_go_to', {name:item.name})">
                                            </v-card-title>
                                            <v-card-text >
                                                <ValidationProvider ref="selected" rules="required"
                                                                    v-slot="{ errors, valid }">
                                                <v-list class="pt-0 mt-0" style="max-height: 300px; overflow-y: auto;"
                                                        ref="list">
                                                    <v-list-item
                                                        v-for="(item, i) in deal_stepItems"
                                                        :key="i"
                                                        :ref="getItemRef(item.id)"
                                                    >
                                                        <v-list-item-title>
                                                            <v-checkbox
                                                                v-model="selected"
                                                                :label="item.name"
                                                                color="red"
                                                                :value="item.id"
                                                                hide-details
                                                            ></v-checkbox>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                </ValidationProvider>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn @click="addButton(item)" :disabled="invalid || loading" :loading="loading"
                                                       :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                                    {{ $t('save') }}
                                                </v-btn>
                                                <v-btn
                                                    :disabled="loading"
                                                    class="ma-1 button_cancel"
                                                    plain
                                                    @click="closeMenu(item)"
                                                >
                                                    {{$t('cancel')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>



                                        </v-menu>
                                        </ValidationObserver>
                                    </template>



                                </div>
                            </template>



                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogCopy"
        >

            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('copy_process') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="dialogCopy=false" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-5">
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="business_process" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="business_process"
                                              :items="businessProcessItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('business_process')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :disabled="loading"
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 pt-10 pb-7">
                        <v-btn @click="copyProcess()" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                            {{ $t('copy') }}
                        </v-btn>
                        <v-btn
                            :disabled="loading"
                            class="ma-1 button_cancel"
                            plain
                            @click="dialogCopy=false"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>

                    </v-card-actions>

                </v-card>
            </ValidationObserver>

        </v-dialog>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: 'DealStep',
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu,
        TabsNav
    },
    props: {
        businessProcessId: {
            default: null
        }
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('deal_step_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            deal_stepItems: [],
            errors: {},
            id: null,
            name: null,
            deal_step: null,
            departments: [],
            headers: [
                {
                    text: "",
                    align: "center",
                    sortable: false,
                    value: "sort",
                    width: 50
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
            ],
            filter_show: false,
            business_process: null,
            businessProcessItems: [],
            dialogCopy: false,
            selected:[],
            menu: {},
            items: [
                {
                    title: 'menu_deal_step',
                    icon: 'icon-user',
                    route: {
                        name: 'deal_step_show',
                        params: {
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },
                {
                    title: 'participants',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_participants',
                        params: {
                            type: 'participant',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },
                {
                    title: 'auditors',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_auditors',
                        params: {
                            type: 'auditor',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                },

                {
                    title: 'moderators',
                    icon: 'icon-clients',
                    route: {
                        name: 'deal_step_moderators',
                        params: {
                            type: 'moderator',
                            business_process: this.$route.params.business_process
                        }
                    },
                    permissions: ['administrator', 'director', 'hr_manager'],
                }
            ],
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang','BusinessProcessId']),
        languages() {
            return this.listLanguages
        },

        process() {
            return this.businessProcessItems.find(item => item.id === parseInt(this.$route.params.business_process))
        },
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.business_process = this.businessProcessId ?? this.$route.params.business_process ?? null
        await this.checkCreate()
        await this.getBusinessProcesses();
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })
        this.fetchDepartments()
    },

    watch: {
        options: {
            handler() {
                this.getDealSteps()
            },
            deep: false
        }
    },
    methods: {
        getItemRef(id) {
            return `item-${id}`; // Генерируем уникальный ref для каждого элемента
        },
        openMenu(item) {
            this.selected = item.button_select;
            this.$set(this.menu, item.id, true);

            // Дожидаемся, пока Vue обновит DOM
            this.$nextTick(() => {
                // Дополнительная задержка для завершения всех анимаций и рендеринга
                setTimeout(() => {
                    const list = this.$refs.list;
                    const selectedItem = item.button_select[0];
                    if (selectedItem) {
                        const rowElements = this.$refs[this.getItemRef(selectedItem)];
                        if (rowElements && rowElements.length > 0) {
                            const row = rowElements[0];
                            if (row && list && row instanceof Element) {
                                const rowRect = row.getBoundingClientRect();
                                const listRect = list.getBoundingClientRect();

                                // Вычисление позиции элемента относительно родительского элемента
                                const rowOffsetTop = rowRect.top - listRect.top + list.scrollTop;

                                console.log('Row:', row);
                                console.log('Row offsetTop relative to list:', rowOffsetTop);
                                console.log('List scrollTop:', list.scrollTop);

                                list.scrollTop = rowOffsetTop;
                            } else {
                                console.error('Row or List is undefined');
                            }
                        } else {
                            console.error('Row elements are empty or undefined');
                        }
                    } else {
                        console.error('Selected item is undefined');
                    }
                }, 500); // 100 миллисекунд задержки
            });
        },
        closeMenu(item) {
            this.$set(this.menu, item.id, false);
            this.selected = [];
        },
        async addButton(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (item && item.id) {
                formData.append('deal_step', item.id)
            }

            if (this.selected && this.selected.length > 0) {
                for (let i in this.selected) {
                    if (this.selected[i] !== 'undefined' && this.selected[i] > 0) {
                        formData.append(`buttons[${i}]`, this.selected[i])
                    }
                }
            }

            await this.$http
                .post(`admin/deal_step/button`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_has_been_updated'))
                    this.closeMenu(item)
                    this.getDealSteps()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_has_not_been_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
            this.heading = this.$t('deal_step_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.deal_stepItems.splice(oldIndex, 1)[0]
            this.deal_stepItems.splice(newIndex, 0, rowSelected)
            this.saveSortDealStep()
        },
        async saveSortDealStep() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.deal_stepItems && this.deal_stepItems.length > 0) {
                for (let i in this.deal_stepItems) {
                    if (this.deal_stepItems[i].id !== 'undefined' && this.deal_stepItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.deal_stepItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/deal_step/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getDealSteps() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.deal_step) {
                params.name = this.deal_step
            }
            if (this.business_process) {
                params.business_process = this.business_process
            }
            else if(this.BusinessProcessId){
                params.business_process = this.BusinessProcessId;
            }

            await this.$http
                .get("admin/deal_step", {
                    params: params,
                })
                .then(res => {
                    this.deal_stepItems = res.body.data
                })
                .catch(err => {
                    this.deal_stepItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteDealStep(item) {
            if (confirm(this.$t('delete_deal_step'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/deal_step/${item.id}`)
                    .then(res => {
                        this.getDealSteps()
                    })
                    .catch(err => {
                        this.DealStepStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addDealStep() {
            this.$router.push({
                name: 'deal_step.create',
                params: {
                    business_process: this.$route.params.business_process
                }
            })
        },
        editDealStep(item) {
            this.$router.push({
                name: 'deal_step.edit',
                params: {
                    id: item.id,
                    business_process: this.$route.params.business_process
                }
            })
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async copyProcess(){
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('business_process_copy', this.business_process)
            formData.append('business_process', this.$route.params.business_process)

            await this.$http
                .post(`admin/deal_step/copy`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_step_copy'))
                    this.business_process = null;
                    this.dialogCopy = null;
                    this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_step_not_copy'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async fetchDepartments() {
            this.loading = true
            await this.$http
                .get("admin/department")
                .then(res => {
                    this.departments = res.body.data
                })
                .catch(err => {
                    this.departments = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
